import i18n from '@configs/locale/i18n';
import { isEmpty } from 'lodash';
import { mapSpaceToSnake } from './string';

export const getMessageError = (error: any) => i18n.t(`error.${error.response.errors[0].extensions.messageCode ?? 'something_went_wrong'}`);

export const getMessageErrorCode = (error: any, field?: string) => {
  let _code: string;
  if (!!field && !isEmpty(error.response.errors[0].extensions?.fields[field])) {
    _code = mapSpaceToSnake(error.response.errors[0].extensions?.fields[field]);
  } else {
    _code = error.response.errors[0].extensions.code ?? error.response.errors[0].extensions.messageCode ?? 'something_went_wrong';
  }
  return i18n.t(`error.${_code}`);
};
