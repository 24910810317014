import { DeviceConnectStatus } from '@graphql/generated';

export const formatNumber = (number: number | null | undefined) => {
  return Number(number || 0)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

/**
 * use when you need to separate number with ','
 * @param number number you need to separate
 * @param decimalLength number of gì vậy ?
 */
export const formatNumberStr = (number: number | string | null | undefined, decimalLength = 3) => {
  if (number === 0) return '0';
  if (!number || isNaN(Number(number))) return '';
  if (number.toString().includes('.')) {
    const numberParseArray = number.toString().split('.');
    const evenSection = numberParseArray[0];
    const decimalSection = numberParseArray[1];
    decimalLength = decimalLength > decimalSection.length ? decimalSection.length : decimalLength;
    const regex = new RegExp(`\\B(?=(\\d{3})+(?!\\d))`, 'g');
    if (!decimalLength) {
      return `${evenSection.replace(regex, '.')},${decimalSection}`;
    }
    return `${evenSection.replace(regex, '.')},${decimalSection.substring(0, decimalLength)}`;
  }
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const mapSpaceToSnake = (value: string) => {
  return value.replaceAll(' ', '_').toLowerCase();
};

export const mapConnectStatusToColor = (status: DeviceConnectStatus) => {
  switch (status) {
    case DeviceConnectStatus.Disconnected:
      return 'red';
    case DeviceConnectStatus.ConnectNotStable:
      return 'orange';
    case DeviceConnectStatus.Available:
      return 'green';
    default:
      return '';
  }
};
