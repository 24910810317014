import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
const TransactionsPage = lazy(() => import('@modules/transactions/pages'));

export const RoutesTransactions = {
  TRANSACTIONS_ROUTE: '/transactions',
};

export const routerTransactions: RouteObject[] = [
  {
    path: '/transactions',
    element: <TransactionsPage />,
  },
];
