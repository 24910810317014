import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
const DevicesPage = lazy(() => import('@modules/devices/pages'));

export const RoutesDevices = {
  DEVICES_ROUTE: '/devices',
};

export const routerDevices: RouteObject[] = [
  {
    path: '/devices',
    element: <DevicesPage />,
  },
];
