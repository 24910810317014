import authHOC from '@bases/hocs/authHOC';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import './layout.scss';

const ContainerAuthLayout = () => {
  return (
    <Fragment>
      <div className="auth-container bg-[url('@assets/images/login-background-1.jpg')]">
        <Outlet />
      </div>
    </Fragment>
  );
};

export default authHOC(ContainerAuthLayout);
