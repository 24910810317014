import {SubscriptionClient} from 'graphql-subscriptions-client';
import env from '@configs/env';
import {getAuthToken} from '../../untils/token'

const GRAPHQL_ENDPOINT = env.wsEndPoint;

// set up the client, which can be reused
const wsClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  reconnect: true,
  connectionParams: () => {
    console.log('=== connectionParams ===');
    return {
      Authorization: `Bearer ${getAuthToken()}`,
    };
  },
  lazy: true, // only connect when there is a query
  connectionCallback: (error: any) => {
    error && console.error(error);
  },
});

// wsClient.onConnected(() => {
//   console.log('onConnected');
// });

// wsClient.onConnecting(() => {
//   console.log('onConnecting');
// });

// wsClient.onDisconnected(() => {
//   console.log('onDisconnected');
// });

// wsClient.onError(() => {
//   console.log('onError');
// });

// wsClient.onReconnected(() => {
//   console.log('onReconnected');
// });

// wsClient.onReconnecting(() => {
//   console.log('onReconnecting');
// });

export default wsClient;