import type { RouteObject } from 'react-router-dom';
import Login from '@modules/auth/pages/Login';

export const RoutesAuth = {
  AUTH: '/auth',
  LOGIN_ROUTE: '/auth/login',
};

export const routerAuth: RouteObject[] = [
  {
    path: '/auth',
    children: [
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
];
