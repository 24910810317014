import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
const OrganizationsPage = lazy(() => import('@modules/organizations/pages'));

export const RoutesOrganizations = {
  ORGANIZATIONS_ROUTE: '/organizations',
};

export const routerOrganizations: RouteObject[] = [
  {
    path: '/organizations',
    element: <OrganizationsPage />,
  },
];
