// @ts-nocheck
import type { GraphQLClient } from 'graphql-request';
import type { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, type UseMutationOptions, type UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
};

export type AdminUpdateDeviceDto = {
  id: Scalars['Float'];
  merchantId?: InputMaybe<Scalars['String']>;
  terminalCode?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationDto = {
  password: Scalars['String'];
  role?: UserRole;
  username: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  apiKey?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  connectStatus?: Maybe<DeviceConnectStatus>;
  id: Scalars['Float'];
  lastConnected?: Maybe<Scalars['Timestamp']>;
  location?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Float']>;
  status?: Maybe<DeviceStatus>;
  terminalCode?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export enum DeviceConnectStatus {
  Available = 'AVAILABLE',
  ConnectNotStable = 'CONNECT_NOT_STABLE',
  Disconnected = 'DISCONNECTED'
}

export enum DeviceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type FilterDeviceDto = {
  code?: InputMaybe<Scalars['String']>;
  connectStatus?: InputMaybe<DeviceConnectStatus>;
  organizationId?: InputMaybe<Scalars['Float']>;
  secretKey?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DeviceStatus>;
};

export type FilterOrganizationDto = {
  id?: InputMaybe<Scalars['Float']>;
  username?: InputMaybe<Scalars['String']>;
};

export type FilterOverviewTransactionDevices = {
  fromDate?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  toDate?: InputMaybe<Scalars['Float']>;
};

export type FilterOverviewTransactionOrganizations = {
  fromDate?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Float']>;
  toDate?: InputMaybe<Scalars['Float']>;
};

export type FilterTransactionDto = {
  deviceClaimed?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Float']>;
};

export type GenAccessTokenDto = {
  refreshToken: Scalars['String'];
};

export type GenAccessTokenResponse = {
  __typename?: 'GenAccessTokenResponse';
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['Float'];
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Float'];
};

export type LoginDto = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['Float'];
  me?: Maybe<Organization>;
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Float'];
};

export type LogoutDto = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminUpdateDeviceInfo: Device;
  changeDeviceStatus: Device;
  createOrganization: Organization;
  genAccessToken: GenAccessTokenResponse;
  login: LoginResponse;
  logout: Scalars['Boolean'];
};


export type MutationAdminUpdateDeviceInfoArgs = {
  adminUpdateDeviceDto: AdminUpdateDeviceDto;
};


export type MutationChangeDeviceStatusArgs = {
  updateDeviceStatusDto: UpdateDeviceStatusDto;
};


export type MutationCreateOrganizationArgs = {
  createOrganizationDto: CreateOrganizationDto;
};


export type MutationGenAccessTokenArgs = {
  genAccessTokenDto: GenAccessTokenDto;
};


export type MutationLoginArgs = {
  loginDto: LoginDto;
};


export type MutationLogoutArgs = {
  logoutDto: LogoutDto;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Float'];
  role: UserRole;
  username: Scalars['String'];
};

export type OverviewDevice = {
  __typename?: 'OverviewDevice';
  availableQuantity?: Maybe<Scalars['Float']>;
  connectNotStableQuantity?: Maybe<Scalars['Float']>;
  disableQuantity?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type OverviewSales = {
  __typename?: 'OverviewSales';
  device?: Maybe<Device>;
  organization?: Maybe<Organization>;
  revenue?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  adminSeeDevices: Array<Device>;
  deviceTransactions: Array<VnpayTransaction>;
  myDevices: Array<Device>;
  organizations: Array<Organization>;
  overviewDevice: OverviewDevice;
  overviewSalesByDevices: Array<OverviewSales>;
  overviewSalesByOrganization: Array<OverviewSales>;
};


export type QueryAdminSeeDevicesArgs = {
  filterDeviceDto: FilterDeviceDto;
};


export type QueryDeviceTransactionsArgs = {
  filterTransactionDto: FilterTransactionDto;
};


export type QueryMyDevicesArgs = {
  filterDeviceDto: FilterDeviceDto;
};


export type QueryOrganizationsArgs = {
  filterOrganizationDto: FilterOrganizationDto;
};


export type QueryOverviewSalesByDevicesArgs = {
  filterOverviewTransactionDeviceDto: FilterOverviewTransactionDevices;
};


export type QueryOverviewSalesByOrganizationArgs = {
  filterOverviewTransactionOrganizations: FilterOverviewTransactionOrganizations;
};

export type UpdateDeviceStatusDto = {
  deviceId: Scalars['Float'];
  status: DeviceStatus;
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type VnpayTransaction = {
  __typename?: 'VnpayTransaction';
  amountAfterDiscount: Scalars['Float'];
  amountBeforeDiscount: Scalars['Float'];
  customerFullName?: Maybe<Scalars['String']>;
  device: Device;
  deviceClaimed: Scalars['Float'];
  id: Scalars['Float'];
  terminalCode: Scalars['String'];
  transactionMappingDate: Scalars['Timestamp'];
};

export type LoginMutationVariables = Exact<{
  input: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken: string, refreshToken: string, accessTokenExpiresAt: number, refreshTokenExpiresAt: number, me?: { __typename?: 'Organization', username: string, role: UserRole } | null } };

export type OverviewDeviceQueryVariables = Exact<{ [key: string]: never; }>;


export type OverviewDeviceQuery = { __typename?: 'Query', overviewDevice: { __typename?: 'OverviewDevice', total?: number | null, disableQuantity?: number | null, availableQuantity?: number | null, connectNotStableQuantity?: number | null } };

export type OverviewSalesByDeviceQueryVariables = Exact<{
  input: FilterOverviewTransactionDevices;
}>;


export type OverviewSalesByDeviceQuery = { __typename?: 'Query', overviewSalesByDevices: Array<{ __typename?: 'OverviewSales', revenue?: number | null, organization?: { __typename?: 'Organization', id: number, username: string, role: UserRole } | null, device?: { __typename?: 'Device', id: number, code: string, name?: string | null } | null }> };

export type OverviewSalesByOrganizationQueryVariables = Exact<{
  input: FilterOverviewTransactionOrganizations;
}>;


export type OverviewSalesByOrganizationQuery = { __typename?: 'Query', overviewSalesByOrganization: Array<{ __typename?: 'OverviewSales', revenue?: number | null, organization?: { __typename?: 'Organization', id: number, username: string, role: UserRole } | null, device?: { __typename?: 'Device', id: number, code: string, name?: string | null } | null }> };

export type ChangeDeviceStatusMutationVariables = Exact<{
  input: UpdateDeviceStatusDto;
}>;


export type ChangeDeviceStatusMutation = { __typename?: 'Mutation', changeDeviceStatus: { __typename?: 'Device', id: number } };

export type AdminDevicesQueryVariables = Exact<{
  input: FilterDeviceDto;
}>;


export type AdminDevicesQuery = { __typename?: 'Query', adminSeeDevices: Array<{ __typename?: 'Device', id: number, code: string, name?: string | null, location?: string | null, lastConnected?: any | null, merchantId?: string | null, terminalCode?: string | null, version?: string | null, status?: DeviceStatus | null, organizationId?: number | null, connectStatus?: DeviceConnectStatus | null, organization?: { __typename?: 'Organization', id: number, username: string, role: UserRole } | null }> };

export type MyDevicesQueryVariables = Exact<{
  input: FilterDeviceDto;
}>;


export type MyDevicesQuery = { __typename?: 'Query', myDevices: Array<{ __typename?: 'Device', id: number, code: string, name?: string | null, location?: string | null, lastConnected?: any | null, merchantId?: string | null, terminalCode?: string | null, version?: string | null, status?: DeviceStatus | null, organizationId?: number | null, connectStatus?: DeviceConnectStatus | null, organization?: { __typename?: 'Organization', id: number, username: string, role: UserRole } | null }> };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationDto;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: number } };

export type OrganizationsQueryVariables = Exact<{
  input: FilterOrganizationDto;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: number, username: string, role: UserRole }> };

export type DeviceTransactionsQueryVariables = Exact<{
  input: FilterTransactionDto;
}>;


export type DeviceTransactionsQuery = { __typename?: 'Query', deviceTransactions: Array<{ __typename?: 'VnpayTransaction', id: number, customerFullName?: string | null, amountBeforeDiscount: number, amountAfterDiscount: number, terminalCode: string, transactionMappingDate: any, deviceClaimed: number, device: { __typename?: 'Device', id: number, code: string } }> };


export const LoginDocument = `
    mutation login($input: LoginDto!) {
  login(loginDto: $input) {
    accessToken
    refreshToken
    accessTokenExpiresAt
    refreshTokenExpiresAt
    me {
      username
      role
    }
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['login'],
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers)(),
      options
    );
useLoginMutation.fetcher = (client: GraphQLClient, variables: LoginMutationVariables, headers?: RequestInit['headers']) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers);
export const OverviewDeviceDocument = `
    query overviewDevice {
  overviewDevice {
    total
    disableQuantity
    availableQuantity
    connectNotStableQuantity
  }
}
    `;
export const useOverviewDeviceQuery = <
      TData = OverviewDeviceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: OverviewDeviceQueryVariables,
      options?: UseQueryOptions<OverviewDeviceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<OverviewDeviceQuery, TError, TData>(
      variables === undefined ? ['overviewDevice'] : ['overviewDevice', variables],
      fetcher<OverviewDeviceQuery, OverviewDeviceQueryVariables>(client, OverviewDeviceDocument, variables, headers),
      options
    );
useOverviewDeviceQuery.fetcher = (client: GraphQLClient, variables?: OverviewDeviceQueryVariables, headers?: RequestInit['headers']) => fetcher<OverviewDeviceQuery, OverviewDeviceQueryVariables>(client, OverviewDeviceDocument, variables, headers);
export const OverviewSalesByDeviceDocument = `
    query overviewSalesByDevice($input: FilterOverviewTransactionDevices!) {
  overviewSalesByDevices(filterOverviewTransactionDeviceDto: $input) {
    revenue
    organization {
      id
      username
      role
    }
    device {
      id
      code
      name
    }
  }
}
    `;
export const useOverviewSalesByDeviceQuery = <
      TData = OverviewSalesByDeviceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: OverviewSalesByDeviceQueryVariables,
      options?: UseQueryOptions<OverviewSalesByDeviceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<OverviewSalesByDeviceQuery, TError, TData>(
      ['overviewSalesByDevice', variables],
      fetcher<OverviewSalesByDeviceQuery, OverviewSalesByDeviceQueryVariables>(client, OverviewSalesByDeviceDocument, variables, headers),
      options
    );
useOverviewSalesByDeviceQuery.fetcher = (client: GraphQLClient, variables: OverviewSalesByDeviceQueryVariables, headers?: RequestInit['headers']) => fetcher<OverviewSalesByDeviceQuery, OverviewSalesByDeviceQueryVariables>(client, OverviewSalesByDeviceDocument, variables, headers);
export const OverviewSalesByOrganizationDocument = `
    query overviewSalesByOrganization($input: FilterOverviewTransactionOrganizations!) {
  overviewSalesByOrganization(filterOverviewTransactionOrganizations: $input) {
    revenue
    organization {
      id
      username
      role
    }
    device {
      id
      code
      name
    }
  }
}
    `;
export const useOverviewSalesByOrganizationQuery = <
      TData = OverviewSalesByOrganizationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: OverviewSalesByOrganizationQueryVariables,
      options?: UseQueryOptions<OverviewSalesByOrganizationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<OverviewSalesByOrganizationQuery, TError, TData>(
      ['overviewSalesByOrganization', variables],
      fetcher<OverviewSalesByOrganizationQuery, OverviewSalesByOrganizationQueryVariables>(client, OverviewSalesByOrganizationDocument, variables, headers),
      options
    );
useOverviewSalesByOrganizationQuery.fetcher = (client: GraphQLClient, variables: OverviewSalesByOrganizationQueryVariables, headers?: RequestInit['headers']) => fetcher<OverviewSalesByOrganizationQuery, OverviewSalesByOrganizationQueryVariables>(client, OverviewSalesByOrganizationDocument, variables, headers);
export const ChangeDeviceStatusDocument = `
    mutation changeDeviceStatus($input: UpdateDeviceStatusDto!) {
  changeDeviceStatus(updateDeviceStatusDto: $input) {
    id
  }
}
    `;
export const useChangeDeviceStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ChangeDeviceStatusMutation, TError, ChangeDeviceStatusMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ChangeDeviceStatusMutation, TError, ChangeDeviceStatusMutationVariables, TContext>(
      ['changeDeviceStatus'],
      (variables?: ChangeDeviceStatusMutationVariables) => fetcher<ChangeDeviceStatusMutation, ChangeDeviceStatusMutationVariables>(client, ChangeDeviceStatusDocument, variables, headers)(),
      options
    );
useChangeDeviceStatusMutation.fetcher = (client: GraphQLClient, variables: ChangeDeviceStatusMutationVariables, headers?: RequestInit['headers']) => fetcher<ChangeDeviceStatusMutation, ChangeDeviceStatusMutationVariables>(client, ChangeDeviceStatusDocument, variables, headers);
export const AdminDevicesDocument = `
    query adminDevices($input: FilterDeviceDto!) {
  adminSeeDevices(filterDeviceDto: $input) {
    id
    code
    name
    location
    lastConnected
    merchantId
    terminalCode
    version
    status
    organizationId
    organization {
      id
      username
      role
    }
    connectStatus
  }
}
    `;
export const useAdminDevicesQuery = <
      TData = AdminDevicesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AdminDevicesQueryVariables,
      options?: UseQueryOptions<AdminDevicesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AdminDevicesQuery, TError, TData>(
      ['adminDevices', variables],
      fetcher<AdminDevicesQuery, AdminDevicesQueryVariables>(client, AdminDevicesDocument, variables, headers),
      options
    );
useAdminDevicesQuery.fetcher = (client: GraphQLClient, variables: AdminDevicesQueryVariables, headers?: RequestInit['headers']) => fetcher<AdminDevicesQuery, AdminDevicesQueryVariables>(client, AdminDevicesDocument, variables, headers);
export const MyDevicesDocument = `
    query myDevices($input: FilterDeviceDto!) {
  myDevices(filterDeviceDto: $input) {
    id
    code
    name
    location
    lastConnected
    merchantId
    terminalCode
    version
    status
    organizationId
    organization {
      id
      username
      role
    }
    connectStatus
  }
}
    `;
export const useMyDevicesQuery = <
      TData = MyDevicesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: MyDevicesQueryVariables,
      options?: UseQueryOptions<MyDevicesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<MyDevicesQuery, TError, TData>(
      ['myDevices', variables],
      fetcher<MyDevicesQuery, MyDevicesQueryVariables>(client, MyDevicesDocument, variables, headers),
      options
    );
useMyDevicesQuery.fetcher = (client: GraphQLClient, variables: MyDevicesQueryVariables, headers?: RequestInit['headers']) => fetcher<MyDevicesQuery, MyDevicesQueryVariables>(client, MyDevicesDocument, variables, headers);
export const CreateOrganizationDocument = `
    mutation createOrganization($input: CreateOrganizationDto!) {
  createOrganization(createOrganizationDto: $input) {
    id
  }
}
    `;
export const useCreateOrganizationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>(
      ['createOrganization'],
      (variables?: CreateOrganizationMutationVariables) => fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(client, CreateOrganizationDocument, variables, headers)(),
      options
    );
useCreateOrganizationMutation.fetcher = (client: GraphQLClient, variables: CreateOrganizationMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(client, CreateOrganizationDocument, variables, headers);
export const OrganizationsDocument = `
    query organizations($input: FilterOrganizationDto!) {
  organizations(filterOrganizationDto: $input) {
    id
    username
    role
  }
}
    `;
export const useOrganizationsQuery = <
      TData = OrganizationsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: OrganizationsQueryVariables,
      options?: UseQueryOptions<OrganizationsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<OrganizationsQuery, TError, TData>(
      ['organizations', variables],
      fetcher<OrganizationsQuery, OrganizationsQueryVariables>(client, OrganizationsDocument, variables, headers),
      options
    );
useOrganizationsQuery.fetcher = (client: GraphQLClient, variables: OrganizationsQueryVariables, headers?: RequestInit['headers']) => fetcher<OrganizationsQuery, OrganizationsQueryVariables>(client, OrganizationsDocument, variables, headers);
export const DeviceTransactionsDocument = `
    query deviceTransactions($input: FilterTransactionDto!) {
  deviceTransactions(filterTransactionDto: $input) {
    id
    customerFullName
    amountBeforeDiscount
    amountAfterDiscount
    terminalCode
    transactionMappingDate
    deviceClaimed
    device {
      id
      code
    }
  }
}
    `;
export const useDeviceTransactionsQuery = <
      TData = DeviceTransactionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: DeviceTransactionsQueryVariables,
      options?: UseQueryOptions<DeviceTransactionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DeviceTransactionsQuery, TError, TData>(
      ['deviceTransactions', variables],
      fetcher<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>(client, DeviceTransactionsDocument, variables, headers),
      options
    );
useDeviceTransactionsQuery.fetcher = (client: GraphQLClient, variables: DeviceTransactionsQueryVariables, headers?: RequestInit['headers']) => fetcher<DeviceTransactionsQuery, DeviceTransactionsQueryVariables>(client, DeviceTransactionsDocument, variables, headers);