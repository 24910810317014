import { DateFormat } from '@configs/common';
import dayjs from 'dayjs';

/**
 * check expiry
 * return true if expired
 * @param date number in seconds
 */
export const isExpired = (date: number) => {
  return date < dayjs().valueOf();
};

export const concatDateTime = (startDate: Date, startTime: Date, endDate: Date, endTime: Date) => {
  const startD = dayjs(startDate).format(DateFormat.DEFAULT);
  const startT = dayjs(startTime).format(DateFormat.TIME_NO_SECOND);
  const endD = dayjs(endDate).format(DateFormat.DEFAULT);
  const endT = dayjs(endTime).format(DateFormat.TIME_NO_SECOND);
  const startAt = dayjs(`${startD} ${startT}`, DateFormat.DATE_TIME_NO_SECOND).valueOf();
  const endAt = dayjs(`${endD} ${endT}`, DateFormat.DATE_TIME_NO_SECOND).valueOf();

  return { startAt, endAt };
};
