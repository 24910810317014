export const TABLE_PAGE_SIZE = 10;

export enum DateFormat {
  DEFAULT = 'DD/MM/YYYY',
  REVERSE = 'YYYY/MM/DD',
  DATE_TIME_NO_SECOND = 'DD/MM/YYYY HH:mm',
  TIME_NO_SECOND = 'HH:mm',
}

export enum Currency {
  VND = 'VND',
}

export const CURRENCY_UNIT = Currency.VND;

export enum TimeDebounce {
  SEARCH = 300,
  NOTIFICATION = 3 * 60 * 1000,
}

export enum Language {
  EN = 'en',
  VI = 'vi',
}

export interface Filter<T> {
  filter: T;
  offset: number;
  limit: number;
}
