import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
const DashboardPage = lazy(() => import('@modules/dashboard/pages'));

export const RoutesDashboard = {
  DASHBOARD_ROUTE: '/',
};

export const routerDashboard: RouteObject[] = [
  {
    path: '/',
    element: <DashboardPage />,
  },
];
