import authHOC from '@bases/hocs/authHOC';
import { Button, Dropdown, Image, Layout, Menu } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { NavigateFunction, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TransactionOutlined,
  VideoCameraOutlined,
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  ApartmentOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import LogoSvg from '@assets/images/logo.svg';
import { systemRoutes } from '@routes/index';
import { useTranslation } from 'react-i18next';
import { getAuthLocalData, removeToken } from '@untils/token';
import { useQueryClient } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { UserRole } from '@graphql/generated';

const { Header, Sider, Content } = Layout;

const menuItems = (t: TFunction, navigate: NavigateFunction) => [
  {
    key: '',
    icon: <DashboardOutlined />,
    label: t('dashboard'),
    onClick: () => navigate(systemRoutes.DASHBOARD_ROUTE),
  },
  {
    key: 'devices',
    icon: <VideoCameraOutlined />,
    label: t('devices'),
    onClick: () => navigate(systemRoutes.DEVICES_ROUTE),
  },
  {
    key: 'transactions',
    icon: <TransactionOutlined />,
    label: t('transactions'),
    onClick: () => navigate(systemRoutes.TRANSACTIONS_ROUTE),
  },
  {
    key: 'organizations',
    icon: <ApartmentOutlined />,
    label: t('organizations'),
    onClick: () => navigate(systemRoutes.ORGANIZATIONS_ROUTE),
    className: getAuthLocalData()?.me?.role !== UserRole.Admin ? '!hidden' : '',
  },
  {
    key: 'reports',
    icon: <ReadOutlined />,
    label: t('reports'),
    children: [
      {
        key: 'revenue_organization',
        label: t('organizations'),
        onClick: () => navigate(systemRoutes.REVENUE_ORGANIZATION_ROUTE),
        className: getAuthLocalData()?.me?.role !== UserRole.Admin ? '!hidden' : '',
      },
      {
        key: 'revenue_device',
        label: t('devices'),
        onClick: () => navigate(systemRoutes.REVENUE_DEVICE_ROUTE),
      },
    ],
  },
];

const ContainerLayout = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const authData = getAuthLocalData();

  useEffect(() => {
    const [_, key] = pathname.split('/');
    setSelectedKeys(key);

    if (key.startsWith('revenue')) {
      setOpenKeys(['reports']);
    }
  }, [pathname]);

  const handleLogout = () => {
    removeToken();
    queryClient.clear();
    navigate(systemRoutes.LOGIN_ROUTE);
  };

  const handleOpenMenu = (keys: string[]) => {
    setOpenKeys(keys);
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} className="min-h-screen">
        <div className="flex items-center justify-center h-16">
          <Image src={LogoSvg} alt="logo" preview={false} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKeys]}
          openKeys={openKeys}
          onOpenChange={handleOpenMenu}
          items={menuItems(t, navigate)}
        />
      </Sider>
      <Layout>
        <Header className="!p-0 !bg-white">
          <div className="flex items-center justify-between ms-3 mr-5">
            <div className="flex items-center">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
              <span className="text-2xl ml-3 font-medium">{t(selectedKeys || 'dashboard')}</span>
            </div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'logout',
                    label: t('logout'),
                    icon: <LogoutOutlined />,
                    onClick: handleLogout,
                  },
                ],
              }}
            >
              <div className="flex items-center gap-2 cursor-pointer">
                <span className="capitalize">{authData?.me?.username || t('account')}</span>
                <UserOutlined />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className="p-5">
          <Suspense>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default authHOC(ContainerLayout);
