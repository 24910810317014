import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
const RevenueByOrganizationPage = lazy(() => import('@modules/reports/pages/RevenueByOrganization'));
const RevenueByDevicePage = lazy(() => import('@modules/reports/pages/RevenueByDevice'));

export const RoutesReports = {
  REVENUE_ORGANIZATION_ROUTE: '/revenue_organization',
  REVENUE_DEVICE_ROUTE: '/revenue_device',
};

export const routerReports: RouteObject[] = [
  {
    path: RoutesReports.REVENUE_ORGANIZATION_ROUTE,
    element: <RevenueByOrganizationPage />,
  },
  {
    path: RoutesReports.REVENUE_DEVICE_ROUTE,
    element: <RevenueByDevicePage />,
  },
];
