import env from '@configs/env';
import dayjs from 'dayjs';
import { isJsonString } from './string';

export const TOKEN_KEY = env.tokenKey;

export const setAuthData = (authData: any) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authData || {}));
};

export const parseTokenString = (str: string) => {
  if (isJsonString(str)) {
    const authObject: any = JSON.parse(str);
    return authObject;
  }
  return null;
};

export const getAuthToken = () => {
  const authData = localStorage.getItem(env.tokenKey);
  if (authData) {
    return JSON.parse(authData).accessToken;
  }
  return null;
};

export const tokenChecker = (authData: any | null) => {
  if (!authData || !authData.accessToken || authData.accessTokenExpiresAt < dayjs().valueOf()) return false;
  return true;
};

export const getAuthLocalDataOnRequest = () => {
  if (typeof localStorage !== 'undefined' && localStorage !== null) {
    const authData = parseTokenString(localStorage?.getItem(TOKEN_KEY) || '');
    if (!tokenChecker(authData)) {
      return null;
    }
    return authData;
  }
  return null;
};

export const getAuthLocalData = () => {
  if (typeof localStorage !== 'undefined' && localStorage !== null) {
    const authData = parseTokenString(localStorage?.getItem(TOKEN_KEY) || '');
    return authData;
  }
  return null;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
