import { OperationVariables } from '@apollo/client';
import env from '@configs/env';
import { systemRoutes } from '@routes/index';
import { isExpired } from '@untils/date';
import { getAuthLocalData, getAuthToken, removeToken, setAuthData } from '@untils/token';
import { gql, GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import wsClient from '@graphql/services/socket-client';
import { useQueryClient } from '@tanstack/react-query';

let requestCheckCounter = 0;
export const endpoint = env.apiEndPoint;

export const resetSubscription = () => {
  wsClient.close();
};

const refreshTokenMutation = async (refreshToken: string) => {
  const client = new GraphQLClient(endpoint, {});
  const mutation = gql`
    mutation genAccessToken($input: GenAccessTokenDto!) {
      genAccessToken(genAccessTokenDto: $input) {
        accessToken
        refreshToken
        accessTokenExpiresAt
        refreshTokenExpiresAt
      }
    }
  `;
  const variables = {
    input: { refreshToken },
  };
  return await client.request(mutation, variables);
};

export const graphqlClientRequest = (auth = true, signal?: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const validateLogout = debounce(() => {
    const authData = getAuthLocalData();
    if (!authData || isExpired(authData?.refreshTokenExpiresAt)) {
      resetSubscription();
      removeToken();
      navigate(systemRoutes.LOGIN_ROUTE);
      queryClient.clear();
    }
  }, 500);

  const headers: any = {};

  if (auth) {
    headers.authorization = `Bearer ${getAuthToken()}`;
  }

  function responseMiddleware(response: any) {
    if (response?.response?.errors[0]?.extensions.code === 'UNAUTHENTICATED') {
      validateLogout();
    }
  }

  async function requestMiddleware(request: RequestInit) {
    const authData = getAuthLocalData();
    if (authData) {
      const currentTime = Date.now();
      const expiresTime = authData?.accessTokenExpiresAt;
      if (expiresTime - currentTime < 300000) {
        requestCheckCounter++;
        if (requestCheckCounter === 1) {
          try {
            const refreshTokenRes = await refreshTokenMutation(authData.refreshToken);
            requestCheckCounter = 0;
            if (refreshTokenRes.genAccessToken.accessToken) {
              setAuthData({ ...refreshTokenRes.genAccessToken, me: authData.me });
              return {
                ...request,
                headers: { ...request.headers, authorization: `Bearer ${refreshTokenRes.genAccessToken.accessToken}` },
              };
            }
          } catch {
            removeToken();
            navigate(systemRoutes.LOGIN_ROUTE);
            queryClient.clear();
          }
        }
      }
    }
    return {
      ...request,
    };
  }

  return new GraphQLClient(endpoint, {
    headers,
    signal,
    responseMiddleware,
    requestMiddleware,
  });
};

export const appSubscription = (query: string, variables?: OperationVariables) => {
  return wsClient.request({
    query,
    variables,
  });
};

export default {
  graphqlClientRequest,
};
