export interface ENV {
  apiEndPoint: string;
  wsEndPoint: string;
  tokenKey: string;
}

const env: ENV = {
  apiEndPoint: process.env.REACT_APP_API_END_POINT || '',
  wsEndPoint: process.env.REACT_APP_API_WS || '',
  tokenKey: process.env.REACT_APP_TOKEN_KEY || '',
};

export default env;
