import { useLogin } from '@modules/auth/services/api';
import { Button, Form, Image, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LogoSvg from '@assets/images/logo.svg';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { mutate: login, isLoading } = useLogin();

  const onFinish = (value: any) => {
    login({ input: { username: value.username, password: value.password } });
  };

  return (
    <>
      <div className="login-content">
        <div className="logo">
          <Image src={LogoSvg} alt="logo" preview={false} />
        </div>
        <Form onFinish={onFinish} layout="vertical" name="login-form">
          <Form.Item
            name="username"
            label={t('username')}
            rules={[
              {
                required: true,
                message: t('error.required'),
              },
            ]}
          >
            <Input size="large" prefix={<UserOutlined />} placeholder={t('username')} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('password')}
            rules={[
              {
                required: true,
                message: t('error.required'),
              },
            ]}
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder={t('password')} />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="w-full" loading={isLoading}>
              {t('login')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Login;
