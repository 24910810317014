import Notification from '@bases/components/Notification';
import { useLoginMutation } from '@graphql/generated';
import { graphqlClientRequest } from '@graphql/services/graphql-client';
import { systemRoutes } from '@routes/index';
import { getMessageError } from '@untils/message';
import { setAuthData } from '@untils/token';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useLoginMutation<Error>(graphqlClientRequest(false), {
    onSuccess(data) {
      setAuthData(data.login);
      navigate(systemRoutes.DASHBOARD_ROUTE);
    },
    onError(error: any) {
      Notification.Error({ message: getMessageError(error) });
    },
  });
};
