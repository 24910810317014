import ContainerAuthLayout from '@layout/ContainerAuthLayout';
import ContainerLayout from '@layout/ContainerLayout';
import NotFoundPage from '@modules/404NotFound/pages';
import { routerAuth, RoutesAuth } from '@modules/auth/route';
import { routerDashboard, RoutesDashboard } from '@modules/dashboard/route';
import { routerDevices, RoutesDevices } from '@modules/devices/route';
import { routerOrganizations, RoutesOrganizations } from '@modules/organizations/route';
import { routerReports, RoutesReports } from '@modules/reports/route';
import { RoutesTransactions, routerTransactions } from '@modules/transactions/route';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

const routers: RouteObject[] = [
  {
    element: <ContainerLayout />,
    children: [
      ...routerDashboard,
      ...routerDevices,
      ...routerTransactions,
      ...routerOrganizations,
      ...routerReports,
      { path: '*', element: <NotFoundPage /> },
    ],
  },
  {
    element: <ContainerAuthLayout />,
    children: [...routerAuth, { path: '*', element: <NotFoundPage /> }],
  },
];

export const systemRoutes = {
  ...RoutesAuth,
  ...RoutesDashboard,
  ...RoutesDevices,
  ...RoutesTransactions,
  ...RoutesOrganizations,
  ...RoutesReports,
};

export const browserRouters = createBrowserRouter(routers);
