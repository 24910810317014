import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-10">
      <Result
        status="404"
        title="404"
        subTitle={t('description')}
        extra={
          <Link to="/">
            <Button type="primary">{t('back_to_home')}</Button>
          </Link>
        }
      />
    </div>
  );
}
