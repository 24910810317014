import { browserRouters } from '@routes/index';
import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import LoadingIndicator from '@bases/components/LoadingIndicator';

const App: React.FC = () => {
  return (
    <Suspense
      fallback={
        <div className="init-loading">
          <LoadingIndicator />
        </div>
      }>
      <RouterProvider router={browserRouters} />
    </Suspense>
  );
};

export default App;
